// Theme Styles
@import "./sass/style.react.scss";

// Default Layout themes
@import "./sass/themes/layout/header/base/light.scss";
@import "./sass/themes/layout/header/menu/light.scss";
@import "./sass/themes/layout/brand/dark.scss";
@import "./sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./sass/themes/layout/header/base/light.scss";
// Dark
// @import "./sass/themes/layout/header/base/dark.scss";
  
// Header Menu themes
// Light
//@import "./sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./sass/themes/layout/aside/dark.scss";
// Light
// @import "./sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

// Flaticon and Flaticon2 theme icons
@import "./plugins/flaticon2/flaticon";
@import "./plugins/flaticon/flaticon";

// Keenthemes icons
@import "./plugins/keenthemes-icons/font/ki";