$tables: 'waiting', 'pending', 'fullfilled', 'rejected';

.uploading-list {
    width: 100%;
    height: 100%;
    $margin: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -#{$margin};

    @each $table in $tables {
        .#{$table}-table {
            flex: 0 0 calc(100%/4 - #{$margin/2});
            margin-bottom: $margin;
            height: auto;

            @media screen and (max-width: 1830px) {
                & {
                    flex: 0 0 calc(100%/2 - #{$margin/2});
                    margin-bottom: $margin;
                }
            }
            @media screen and (max-width: 1100px) {
                & {
                    flex: 0 0 calc(100%);
                    margin-bottom: $margin;
                }
            }
        }
    } 
    
    .uploading-list-scrollbar {
        position: relative; 
        height: 100%; 
        overflow: hidden; 
        padding-right: 10px;
    }
}