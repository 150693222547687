.participants-multiple-add-bar {
    padding: 25px;

    .processing-list {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 0;
        margin-top: 10px;
        .title {
            width: 100%;
            font-size: 12px;
            margin-left: 5px;
        }

        .processing-item {
            list-style: none;
            padding: 10px;
            width: fit-content;
            height: fit-content;
            margin: 5px;
            transition: background-color 0.3s ease;

            &.valid {
                background-color: var(--success);
                color: white;
            }
            &.invalid {
                background-color: var(--danger);
                color: white;
            }
        }
    }

    .waiting-list {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;

        .title {
            width: 100%;
            font-size: 12px;
            margin-left: 5px;
        }

        .waiting-item {
            list-style: none;
            padding: 10px;
            width: fit-content;
            height: fit-content;
            margin: 5px;

            background-color: var(--secondary);
            color: black;
        }
    }
}