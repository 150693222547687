.main-page-card {
    display: flex;
    width: 100%;
    justify-content: center;
}

.card-header-title {
    width: fit-content;
    height: 100%;
    display: flex;
    align-self: center;
}

.card-header-toolbar {
    align-self: center;
}