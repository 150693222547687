@import "~fluid-player/src/css/fluidplayer.css";

.unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                    not supported by any browser */
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.rotating-icon {
    animation:spin-icon 1s linear infinite;
}

.overflow-x-none {
    overflow-x: hidden !important;
}

@keyframes spin-icon { 
    100% { 
        -webkit-transform: rotate(-360deg); 
        transform:rotate(-360deg); 
    } 
}