//
// Topbar
//

.topbar {
	display: flex;
	align-items: stretch;
	padding: 0;

	// Topbar Item
	.topbar-item {
		display: flex;
		align-items: center;
	}

	// Topbar Dropdown
	.dropdown {
		display: flex;
		align-items: stretch;
	}
}

// Tablet & Mobile Modes
// @include media-breakpoint-down(md) {
// 	.topbar {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		transition: all 0.3s ease;
// 		justify-content: flex-end;
// 		padding: 0 get($page-padding, tablet-and-mobile);
// 		height: get($header-topbar-config, tablet-and-mobile, height);
// 		background-color: get($header-topbar-config, tablet-and-mobile, bg-color);
// 		box-shadow: get($header-topbar-config, tablet-and-mobile, shadow);
// 		border-top: 1px solid transparent;

// 		// Fixed Mobile Header
// 		.header-mobile-fixed & {
// 			position: fixed;
// 			z-index: get($header-config, tablet-and-mobile, fixed, zindex) - 1;
// 		}

// 		// Topbar Shown Mode
// 		.topbar-mobile-on & {
// 			z-index: 2;
// 			transition: all 0.3s ease;
// 			border-top: 1px solid get($header-topbar-config, tablet-and-mobile, border-color);
// 			margin-top: get($header-config, tablet-and-mobile, default, height);
// 		}

// 		// Fixed Mobile Header & Topbar Shown Modes
// 		.topbar-mobile-on.header-mobile-fixed & {
// 			z-index: get($header-config, tablet-and-mobile, fixed, zindex) - 1;
// 			margin-top: get($header-config, tablet-and-mobile, fixed, height);
// 		}
// 	}
// }

// // Mobile View
// @include media-breakpoint-down(xs) {
// 	.topbar {
// 		.topbar-item {
// 			.btn.btn-icon {
// 				height:  35px;
//    				width:  35px;
// 			}
// 		}
// 	}
// }
