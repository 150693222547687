$margin: 10px;
$maxHeight: 220px;

.seasons-card {
    width: 100%;
}

.seasons-collection-content {
    $count: 10;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-right: 7px;
    max-height: $maxHeight;

    .season {
        flex: 0 0 calc(#{100/$count}% - #{$margin});
        margin: $margin/2;
        padding: 7px;
        height: 100px;
        border-radius: 7px;
        cursor: pointer;
        position: relative;
        background-color: #1e1e2d;
        color: #e7e7e7;

        &.active {
            box-shadow: 0px 3px 0px 1px #316def, 0px -3px 0px 1px #316def;
        }
        &.add-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: box-shadow 0.5s linear;
            &:active {
                box-shadow: 0 0 5px 3px #316def !important;
            }
        }

        .season-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @media screen and (max-width: 1400px) {
            & {
                $count: 8;
                flex: 0 0 calc(#{100/$count}% - #{$margin});
            }
        }
        @media screen and (max-width: 1200px) {
            & {
                $count: 6;
                flex: 0 0 calc(#{100/$count}% - #{$margin});
            }
        }
    }
}

.seasons-collection-scrollbar {
    position: relative; 
    max-height: $maxHeight; 
    overflow: hidden; 
}

.seasons-collection-title {
    width: 100%;
    display: flex;
    align-items: center;
}