.card-media-wrapper {
    display: flex;
    position: relative;
    height: 100%;
    padding: 20px;
}

.card-media {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
    width: 100%;
}