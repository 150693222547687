// Removes '!' icon from invalid input fields
.was-validated {
    .form-control {
        &:invalid {
            background-image: none ;
        }
    }
}

.form-control {
    &.is-invalid, &.is-valid {
        background-image: none ;
    }
}

// stretch-out the datepicker container
.react-datepicker-wrapper {
    width: 100%;
}