$margin: 25px;

@mixin flexHalf($margin) {
    flex: 0 0 calc(100%/2 - #{$margin/2});
}

.player-film {
    height: auto;
    @include flexHalf($margin);
    width: 220px;
}

.film-card {
    @include flexHalf($margin);
    height: auto;
    width: 220px;
    margin-bottom: 0;

    .film {
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 7px;
        cursor: pointer;
        position: relative;
        background-color: #1e1e2dda;
        color: #a2a3b7;

        &.active {
            box-shadow: 0px 3px 0px 1px #316def, 0px -3px 0px 1px #316def;
        }

        .film-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button {
                z-index: 3;
            }
        }
    }
}