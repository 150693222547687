@use "sass:list" as math;
$selectors: 'genres', 'tags', 'countries', 'languages', 'participants';

@mixin includeIfNotInList($exculudeList, $current) {
    $NotInList: index($exculudeList, $current) == null;
    @if($NotInList) {
        @content
    }
}

.selectors-config {
    $margin: 25px;
    display: flex;
    flex-wrap: wrap;
    margin: #{-$margin/2};

    @each $selector in $selectors {
        .#{$selector}-config-card {
            margin: #{$margin/2};
            flex: 0 0 calc(100%/4 - #{$margin});
            @media screen and (max-width: 1830px) {
                @include includeIfNotInList('participants', $selector) {
                    & {
                        flex: 0 0 calc(100%/2 - #{$margin});
                    }
                }
            }
            @media screen and (max-width: 1100px) {
                @include includeIfNotInList('participants', $selector) {
                    & {
                        flex: 0 0 calc(100% - #{$margin});
                    }
                }
            }

            @if($selector == 'participants') {
                flex: 0 0 calc(100% - #{$margin});
            }
        }
    }
}

.selectors-config-scrollbar {
    position: relative; 
    height: 60vh; 
    overflow: hidden; 
    padding-right: 10px;
}

.selectors-config-searchbar {
        margin-right: 10px;
        position: relative;
        display: flex;

        .add-label {
            flex-wrap: wrap;
            align-items: center;
            position: absolute;
            top: -20px;
            left: 0;
            z-index: 0;
            span {
                background-color: var(--blue);
                color: whitesmoke;
                font-family: 'Poppins', Helvetica, 'sans-serif';
                font-size: 10px;
                border-radius: 3px;
                padding: 2px 3px;
                width: auto;
            }
            p {
                margin: 0;
                margin-left: 5px;
            }
        }

        button {
            position: absolute;
            right: 8px;
            display: flex;
            align-self: center;
            align-items: center;
            width: 10px;
            height: auto;
            padding: 0;
            cursor: pointer;
            background-color: transparent;
            border: none;
            svg {
                width: 100%;
                height: 100%;
            }
            &:active {
                width: 12px;
            }
        }

        &.participants {
            .add-options {
                width: 110px;
                font-size: 10px;
                position: static;
                right: 25px;
                top: 0;
                bottom: 0;
                z-index: 1;
            }
        }
    }