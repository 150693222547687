$margin: 10px;
$maxHeight: 400px;

.episodes-card {
    flex: 0 0 calc(70% - #{$margin/2});
    height: 502px;
    margin-bottom: 0;
}

.episodes-collection-content {
    $count: 6;
    width: 100%;
    display: flex;
    align-items:flex-start;
    flex-wrap: wrap;
    padding-right: 7px;
    // min-height: $maxHeight;

    .episode {
        flex: 0 0 calc(#{100/$count}% - #{$margin});
        margin: $margin/2;
        padding: 7px;
        min-height: 167px;
        border-radius: 7px;
        cursor: pointer;
        position: relative;
        background-color: #1e1e2d;
        color: #e7e7e7;

        &.active {
            box-shadow: 0px 3px 0px 1px #316def, 0px -3px 0px 1px #316def;
        }
        &.add-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: box-shadow 0.5s linear;
            margin-top: auto;
            margin-bottom: auto;
            &:active {
                box-shadow: 0 0 5px 3px #316def !important;
            }
        }

        .episode-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                z-index: 3;
            }
        }
        @media screen and (max-width: 1600px) {
            & {
                $count: 5;
                flex: 0 0 calc(#{100/$count}% - #{$margin});
            }
        }
        @media screen and (max-width: 1400px) {
            & {
                $count: 4;
                flex: 0 0 calc(#{100/$count}% - #{$margin});
            }
        }
        @media screen and (max-width: 1300px) {
            & {
                $count: 3;
                flex: 0 0 calc(#{100/$count}% - #{$margin});
            }
        }
        @media screen and (max-width: 1200px) {
            & {
                $count: 2;
                flex: 0 0 calc(#{100/$count}% - #{$margin});
            }
        }
    }
}

.episodes-collection-scrollbar {
    position: relative; 
    max-height: $maxHeight; 
    overflow: hidden; 
}

.episodes-collection-title {
    width: 100%;
    display: flex;
    align-items: center;
}