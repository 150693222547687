$tables: 'ready-to-send', 'waiting', 'pending', 'fullfilled', 'rejected';

.processing-list {
    width: 100%;
    height: 100%;
    $margin: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -#{$margin};

    @each $table in $tables {
        .#{$table}-table {
            flex: 0 0 calc(100%/3 - #{$margin/3});
            margin-bottom: $margin;
            height: auto;

            @media screen and (max-width: 1830px) {
                & {
                    flex: 0 0 calc(100%/2 - #{$margin/2});
                }
            }
            @media screen and (max-width: 1100px) {
                & {
                    flex: 0 0 calc(100%);
                }
            }
        }
    }
    
    .processing-list-scrollbar {
        position: relative; 
        height: 100%; 
        overflow: hidden; 
        padding-right: 10px;
    }
}