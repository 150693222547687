// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~bootstrap/scss/_mixins.scss';

// Metronic styles
@import './metronic/styles/index.scss';
// Applications custom styles
@import './app/styles/index.scss';