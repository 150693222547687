$addCards: 'basic-info', 'selectors', 'images';

.content-add-container {
    $margin: 25px;

    @each $card in $addCards {
        .add-#{$card}-card {
            @if($card == 'images') {
                width: 100%;
            } @else {
                flex: 0 0 calc(50% - #{$margin/2});
            }
            
            .add-form-submit-btn{
                height: 30px;
                width: 90px;
            }
        }
    }
}

.error-status-bar {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    
    .error-status-bar-message {
        background-color: #FFE2E5;
        font-size: 11px;
        font-family: Poppins, Helvetica, sans-serif;
        max-width: 500px;
        color: red;
        margin: 5px;
        // padding: 2px 5px;
    }
}

// Edit images section styles
@import './add-images.scss'