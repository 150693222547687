$imageUploader: 'vertical' 'horizontal' 'title';

@mixin fluid {
    width: 100%;
    height: 100%;
}

@mixin contain-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.image-upload-card {
    flex: 1 1 25%;
}

.edit-image-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: -20px; // Fix for margin-bottom in image cards
    width: 100%;

    @each $uploader in $imageUploader {
        .#{$uploader}-image-uploader {
            position: absolute;
            @include contain-absolute;
        }
    }
}

.dropzone {
    z-index: 1;
    box-shadow: 0 0 3px rgb(206, 206, 206);
    @include contain-absolute;
    &.active {
        background-color: rgba(17, 17, 17, 0.3)
    }
}