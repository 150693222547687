$margin: 0px;

.player-trailer {
    height: auto;
    flex: 0 0 calc(100% - #{$margin/2});
    width: 220px;
}

.trailer-card {
    flex: 0 0 calc(100% - #{$margin/2});
    height: auto;
    .edit-files-trailer {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .trailer {
        width: 300px;
        height: 150px;
        padding: 10px;
        border-radius: 7px;
        cursor: pointer;
        position: relative;
        background-color: #000000;
        color: #a2a3b7;

        .trailer-element {
            width: 100%;
            height: 100%;
        }

        &.active {
            box-shadow: 0px 3px 0px 1px #316def, 0px -3px 0px 1px #316def;
        }

        .trailer-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button {
                z-index: 3;
            }
        }
    }

    .trailer-player {
        width: 300px;
        height: 150px;
    }
}