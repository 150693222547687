$margin: 10px;
$maxHeight: 400px;

.player-card {
    flex: 0 0 calc(30% - #{$margin/2});
    height: 502px;
    margin-bottom: 0;
}

.player-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.player-title {
    width: 100%;
    display: flex;
    align-items: center;
}