.participant-image-column {
    width: 80px;
    height: 80px;
}

.participant-image-column .card-media-wrapper {
    padding: 0;
}

.avatar-image-uploader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}