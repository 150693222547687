.list-search-input {
    display: flex;
    position: relative;
}
.list-search-input button {
    position: absolute;
    right: 8px;
    display: flex;
    align-self: center;
    align-items: center;
    width: 10px;
    height: auto;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
.list-search-input button svg {
    width: 100%;
    height: 100%;
}
.list-search-input button:active {
    width: 12px;
}